@import url("https://fonts.googleapis.com/css2?family=Figtree:wght@400;500;600&display=swap");

body {
  margin: 0;
  font-family: "Figtree", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3efe7;
}

.container {
  background-color: #fff;
  padding: 30px;
  border-radius: 12px;
  max-width: 700px;
  margin: 0px auto;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.header {
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: 600;
  text-align: center;
}

.form {
  display: flex;
  flex-direction: column;
}

.radio-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.radio-label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
}

.radio-input {
  margin-right: 8px;
}

.submit-button {
  padding: 12px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #333;
}

.popup {
  text-align: center;
}

h2 {
  margin-bottom: 15px;
  text-align: center;
}

.continue-button {
  display: block;
  margin: 15px auto;
  padding: 10px 20px;
  background-color: #000;
  color: white;
  border-radius: 5px;
  text-decoration: none;
  text-align: center;
  width: fit-content;
}

.continue-button:hover {
  background-color: #444;
}

.whatsapp-input-container {
  display: flex;
  gap: 10px;
}

.country-code-dropdown {
  width: 80px;
}

.whatsapp-input {
  flex-grow: 1;
  width: 100%;
}

.input-group {
  margin-bottom: 20px;
}

.label {
  display: block;
  margin-bottom: 10px;
}

.whatsapp-input-group {
  display: flex;
  align-items: center;
  gap: 10px; 
  height: 30px;
}

.country-code-dropdown {
  width: 150px;
}

.input-field {
  height: 40px; 
  padding: 5px 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

select.input-field {
  width: 200px; 
}

input.input-field {
  width: 250px; 
}

.country-code-dropdown,
.whatsapp-input {
  height: 30px; 
  padding: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.dropdown-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.dropdown-search-input {
  height: 40px; 
  padding: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 8px; 
}

.submit-button {
  padding: 10px 20px;
  background-color: #161525;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #161525;
}

.input-group {
  position: relative;
}

.info-button {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #161525;
  color: white;
  font-size: 12px;
  margin-left: 5px;
  position: relative;
}

.info-tooltip {
  display: none;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  min-width: 200px;
}

.info-button:hover .info-tooltip {
  display: block;
  opacity: 1;
}

.onboarding-options {
  display: flex;
  gap: 20px;
}

.onboarding-options .card {
  background-color: #2c2c2c;
  padding: 20px;
  border-radius: 12px;
  flex: 1;
  text-align: center;
  color: white;
}

.recommended {
  border: 2px solid transparent;
  background-image: linear-gradient(#2c2c2c, #2c2c2c),
    linear-gradient(to right, #8CE9B5, #8CE9B5);
  background-origin: border-box;
  background-clip: content-box, border-box;
  position: relative;
}

.onboarding-options .label {
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ff4081;
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
  color: white;
}

h2 {
  margin: 10px 0;
  font-size: 24px;
}

p {
  color: #666666;
  font-size: 16px;
}

.head {
  text-align: center;
}

.explore {
  border: 2px solid #555;
}

.onboarding-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-container {
  display: flex;
  justify-content: flex-start;
  width: 95%;
  padding: 20px;
}

.logo {
  width: 200px;
  height: auto;
}

.text-white {
  color: #ffffff;
}

/* for mobile screens */
@media (max-width: 768px) {

  .popup-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    width: 100%;
  }

  .logo-card-container {
    flex-direction: column;
  }

  .logo-container {
    margin-right: 0;
    margin-bottom: 0px;
    display: flex;
    justify-content: center;
  }

  .logo {
    width: 150px;
    height: auto;
  }

  .loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 9999;
  }

  .loader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.permission-denied-container {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
  color: #721c24;
}

.permission-denied-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.permission-denied-message {
  font-size: 16px;
  max-width: 600px;
}

.icon-container {
  margin-bottom: 20px;
}

.icon-container svg {
  color: #ff6b6b;
  width: 64px;
  height: 64px;
}

.permission-denied-card {
  padding: 30px;
  text-align: center;
}


/* popup styles */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 90%;
  max-width: 500px;
}

.subtext {
  font-size: 0.9em;
  color: #666;
  margin-top: 10px;
}

.popup-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}

.button-accept,
.button-cancel {
  margin: 10px;
  padding: 10px 20px;
  font-size: 1em;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.button-accept {
  background-color: #161525;
  color: white;
}

.button-cancel {
  background-color: #ffffff;
  color: #161525;
  border: 1px solid #0B57D0;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2; /* Adjust the opacity for the fade-out effect */
  }
  100% {
    opacity: 1;
  }
}

